@import "../../global.sass"

.footer 
    bottom: 0
    left: 0
    width: 100%
    height: 120px
    background-color: $c1
    overflow-y: auto
    

.footer-text-left
    top: 50%
    position: relative
    left: 15%
    transform: translate(-50%, -50%)
    padding-top: 15px
    float: left
    color: $c3
    margin: 0



a.menu
    color: $c4

.footer-content-right 
    padding-right: 40px
    margin-top: 20px
    float: right

.icon-style
    height: 50px
    margin-left: 20px
    margin-top: 5px

.icon-style:hover 
    padding: 5px