@import "../../global.sass"
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap')

.header
    box-sizing: border-box
    margin: 0
    padding: 0
    background-color: $c1
    width: 100%
    color: $c4
    text-decoration: none
    align-items: center
    padding: 20px 10%
    position: fixed

#header
    box-sizing: border-box
    margin: 0
    padding: 0
    background-color: $c1
    width: 100%
    color: $c4
    text-decoration: none
    align-items: center
    padding: 20px 10%
    position: fixed

li, a
    font-family: 'Poppins', sans-serif
    font-weight: 400
    font-size: 16px
    color: $c4
    text-decoration: none

.nav-link
    font-size: 16px
    color: $c4


.navLinks
    list-style: none
    text-decoration: none

.navLinks li
    display: inline-block
    text-decoration: none
    padding: 0 20px

.navLinks li a 
    transition: all 0.3s ease 0s
    text-decoration: none
    color: $c4

.navLinks li a:hover 
    color: $c3
    text-decoration: none

.logo
    cursor: pointer
    font-family: 'Poppins', sans-serif
    color: $c4
    font-size: 20px
    text-decoration: none

.navleft
    float: left
    background-color: $c2
    text-align: left


.navright
    float: right
    background-color: $c5
    text-align: right

#navitem
    color: $c4

#navleft
    float: left
    background-color: $c1
    text-align: left


#navright
    float: right
    background-color: $c1
    text-align: right