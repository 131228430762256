$c1: #6b705c
$c2: #a5a58d
$c3: #b7b7a4
$c4: #ffe8d6
$c5: #ddbea9
$c6: #cb997e
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap')

.title
    background-color: $c3
    padding: 50px

.titletext
    border-bottom: solid
    border-top: solid
    text-align: left
    font-family: 'Poppins', sans-serif