@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.App {
    height: 100vh;
    font-family: 'Poppins', sans-serif;
}
    .sections {
        width: 100%;
        overflow: auto;
        height: calc(100vh - 70px);
        // background-color: lightsalmon;
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none;
         
        &::-webkit-scrollbar {
            display: none;
        }
        // > * {
        //     width: 100vw;
        //     height: calc(100vh - 70px);
        //     scroll-snap-align: start;
        // }
    }