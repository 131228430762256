@import "../../global.sass"

.Portfolio
    background-color: $c3
    text-align: center

.Portfolio::-webkit-scrollbar 
  display: none
    
a
    float: right

img
    white-space: nowrap

.projects
    transition: 0.3s
    width: 95%
    margin: auto
    font-size: 20px
    
    
.gallery
    padding: 5px

.right
    float:right
    padding-right:10px

.desc
    float: left
    padding-left:10px
    font-family: 'Poppins', sans-serif

.moreinfo
    font-size: 12px
    float: left
    padding-left:10px
    font-family: 'Poppins', sans-serif

.skillused
    font-size: 12px
    float: left
    padding-top: 5px
    padding-left:10px
    font-family: 'Poppins', sans-serif

.badge
    font-family: 'Poppins', sans-serif