@import "../../global.sass"

.Intro
    background-color: $c2
    font-family: 'Poppins', sans-serif

.Intro::-webkit-scrollbar 
  display: none

.Profile
  float: left
  margin-left: 125px
  margin-top: 20px
  width: 60%
  height: 60%
  border-radius: 50%
  overflow: hidden
  clip-path: circle()
  

.left
    float: left
    width: 50%

.right
    float: right
    width: 50%

.text
  font-family: 'Poppins', sans-serif
  margin: left
  padding: 75px
  overflow-y: hidden

.icons
  float: left
  margin-left: 20px
  padding: 10px

a
  padding: 3px

.pin
  margin-left: 25px
  overflow-y: hidden

i
  font-family: 'Poppins', sans-serif
  font-size: 20px
  overflow-y: hidden

h4
  overflow-y: hidden

img
  overflow-y: hidden