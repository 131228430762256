@import "../../global.sass"

.Experience
    background-color: $c3
    text-align: center

.Experience::-webkit-scrollbar 
  display: none

.job
    box-shadow: 0 4px 0 0 rgba(0,0,0,0.2)
    transition: 0.3s
    width: 93%
    margin: auto
    text-align: left

h2
    padding: 5px
    margin: 0
    font-family: 'Poppins', sans-serif

h6
    padding: 5px
    margin: 10px

pre
    font-size: inherit
    color: inherit
    border: initial
    padding: initial
    font-family: 'Poppins', sans-serif
    white-space: pre-wrap

li
    color: rgb(0, 0, 0)

.jobtitle
    font-family: 'Poppins', sans-serif
    padding-left: 20px
    padding-top: 20px

.time
    font-family: 'Poppins', sans-serif
    padding-left: 20px
    font-style: italic
    font-size: 14px

.company
    font-family: 'Poppins', sans-serif
    padding-left: 24px
    font-size: 20px

.rightjob
    position: absolute
    right: 0px
    width: 20%