@import "../../global.sass"

h1,h2,h3
    padding: 20px
    overflow: hidden
    

.Skills
    background-color: $c3
    text-align: center
    font-family: 'Poppins', sans-serif
    

.skillgroup
    display: flex
    flex-direction: row

.subskill
    box-shadow: 0 4px 0 0 rgba(0,0,0,0.2)
    transition: 0.3s
    width: 90%
    margin: auto
    font-family: 'Poppins', sans-serif
    font-size: 20px

.skillset
    text-align: left


.Skills::-webkit-scrollbar 
  display: none

li
    padding: 5px

.title
    text-align: center

.skillspace
    margin-right: 5px